import Api from '@/services/Api'

const resource = '/questions'

export default {

  getQuestionsByPage (params = {}) {
    return Api().get(`${resource}/market?size=12`, {
      params: params
    })
  },
  createQuestionExcel(credentials) {
    return Api().post(`${resource}/create/file`, credentials)
  },
  createQuestionZip(credentials) {
    return Api().post(`${resource}/upload/media`, credentials)
  },
  getQuestions(params = {}) {
    return Api().get(`${resource}?size=12`, {
      params: params
    })
  },
  getQuestionsData(params = {}){
    return Api().get(`${resource}/mapping`,{
      params:params
    })
  },
  getQuestionDetails(id) {
    return Api().get(`${resource}/${id}`)
  },
  deleteQuestion(credentials) {
    return Api().post(`${resource}/delete`, credentials)
  },
  searchQuestion() {
    return Api().get(`${resource}/search`)
  },

}
