<template>
  <layout name="OrganizLayout">
    <div class="Home">
      <!-- HERO -->
      <page-header/>

      <section class="uk-section uk-padding-remove">
        <div class="uk-container">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <a>{{$t("organization.quesPurchased.home")}}</a>
              </li>
              <li>
                <span>{{$t("organization.quesPurchased.questPurch")}}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="uk-section uk-padding-small">
        <div class="uk-container">
          <div class="uk-overflow-auto">
            <div class="uk-card uk-card-default uk-width-1-1 uk-margin-remove-left" uk-grid>
              <table class="uk-table uk-table-responsive uk-padding-remove uk-table-small uk-width-1-1@m uk-margin-remove-left">
                <tbody class="uk-padding-remove">
                  <tr>
                    <td class="tit uk-text-bold" style="border-top: 3px solid rgb(1, 176, 255) !important;">{{$t("organization.quesPurchased.eCate")}}</td>
                    <td class="uk-text-left" style="border-top: 3px solid rgb(1, 176, 255) !important;">
                      <select
                        class="uk-select uk-form-width-medium uk-form-small"
                        @change="categoryChange('exam', 'main')"
                        v-model="search.cate1">
                      <option value="">{{$t("additionOne.selectCat")}}</option>
                        <template v-for="(item, index) in mainCategory.main">
                          <option :value="item.id" :key="index">{{item.name}}</option>
                        </template>
                      </select>
                      <select
                        @change="categoryChange('exam', 'middle')"
                        class="uk-select uk-form-width-medium uk-form-small uk-margin-left"
                        v-model="search.cate2">
                        <option value="">{{$t("additionOne.selectCat")}}</option>
                        <template v-for="(item, index) in mainCategory.middle">
                          <option :value="item.id" :key="index">{{item.name}}</option>
                        </template>
                      </select>
                      <select
                        @change="categoryChange('exam', 'low')"
                        class="uk-select uk-form-width-medium uk-form-small uk-margin-left"
                        v-model="question.mainCategory.low">
                        <option value="0">{{$t("additionOne.selectCat")}}</option>
                        <template v-for="(item, index) in mainCategory.low">
                          <option :value="item.id" :key="index">{{item.name}}</option>
                        </template>
                      </select>
                    </td>
                    <td class="tits uk-text-bold" style="border-top: 3px solid rgb(1, 176, 255) !important;">{{$t("organization.quesPurchased.ques")}}</td>
                    <td style="border-top: 3px solid rgb(1, 176, 255) !important;">
                      <input
                          type="text"
                          :placeholder="$t('wHere')"
                          class="uk-input uk-form-width-medium uk-form-small"
                          v-model="search.question"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="tit uk-text-bold">
                      <span class="input_require">{{$t("organization.quesPurchased.quesType")}}</span>
                    </td>
                    <td class="uk-text-left">
                      <input
                        class="uk-radio"
                        type="radio"
                        value="1"
                        v-model="search.qtype"
                      /> <span class="uk-margin-small-left">{{$t("organization.quesPurchased.choiceA")}}</span>
                      <input
                        class="uk-radio uk-margin-left"
                        type="radio"
                        value="5"
                        v-model="search.qtype"
                      /> <span class="uk-margin-small-left">{{$t("organization.quesPurchased.choiceR")}}</span>
                      <input
                        class="uk-radio uk-margin-left"
                        type="radio"
                        value="3"
                        v-model="search.qtype"
                      /> <span class="uk-margin-small-left">{{$t("organization.quesPurchased.sAnswer")}}</span>
                      <input
                        class="uk-radio uk-margin-left"
                        type="radio"
                        value="4"
                        v-model="search.qtype"
                      /><span class="uk-margin-small-left"> {{$t("organization.quesPurchased.speaking")}} </span>
                    </td>
                    <td class="tits uk-text-bold">{{$t("organization.quesPurchased.point")}}</td>
                    <td>
                      <input
                          name="point"
                          type="number"
                          :placeholder="$t('wHere')"
                          v-model="search.point"
                          class="uk-input uk-form-width-medium uk-form-small"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="tit uk-text-bold">{{$t("organization.quesPurchased.quesLevel")}}</td>
                    <td colspan="3" class="uk-text-left">
                      <input
                          name="difficultyLevel"
                          type="radio"
                          value="1"
                          class="uk-radio"
                          v-model="search.qlevel"
                      /> <span
                        class="uk-margin-small-left"> {{$t("organization.questionList.low3")}}</span>
                      <input
                          name="difficultyLevel"
                          type="radio"
                          value="2"
                          class="uk-radio uk-margin-left"
                          v-model="search.qlevel"
                      /> <span
                        class="uk-margin-small-left"> {{$t("organization.questionList.low2")}}</span>
                      <input
                          name="difficultyLevel"
                          type="radio"
                          value="3"
                          class="uk-radio uk-margin-left"
                          v-model="search.qlevel"
                      /> <span class="uk-margin-small-left"> {{$t("organization.questionList.medium")}}</span>
                      <input
                          name="difficultyLevel"
                          type="radio"
                          value="4"
                          class="uk-radio uk-margin-left"
                          v-model="search.qlevel"
                      /> <span
                        class="uk-margin-small-left"> {{$t("organization.questionList.high")}}</span>
                      <input
                          name="difficultyLevel"
                          type="radio"
                          value="5"
                          class="uk-radio uk-margin-left"
                          v-model="search.qlevel"
                      /> <span
                        class="uk-margin-small-left"> {{$t("organization.questionList.high2")}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section class="uk-section uk-padding-remove">
        <div class="uk-container">
          <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
            <div class="uk-width-1-4@m"></div>
            <div class="uk-width-1-4@m"></div>
            <div class="uk-width-1-4@m"></div>
            <div class="uk-width-1-4@m uk-margin-remove-left" uk-grid>
                <button type="button" class=" uk-flex uk-flex-right uk-width-2-3@m qbtn_d uk-button"
                        style="background-color: white; color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)" @click="defaultSetting">
                  {{$t("organization.quesPurchased.dSet")}}
                </button>
                <button type="button" class="qbtn_d uk-button uk-width-1-3@m"
                style="background-color: rgb(1, 176, 255);"
                @click="searchFilter()">{{$t("organization.quesPurchased.search")}}</button>
            </div>
          </div>
        </div>
      </section>
      <section class="uk-section uk-padding-remove">
        <div class="uk-container">
          <div class="uk-card uk-card-default uk-card-small uk-margin-top">
            <header class="uk-card-header" style="border-top: 3px solid rgb(1, 176, 255) !important;">
              <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small" >
                  <button
                    class="uk-button uk-button-small uk-button-danger"
                    @click="removeSelected()">
                    {{$t("organization.quesPurchased.dAll")}}</button>
                  <span style="margin-right:10px" class=" uk-margin-left  uk-flex uk-flex-middle">Sort by:</span>
                  <div uk-form-custom="target: > * > span:first-child">
                    <select v-model="sortselected" @change="onChangeSort($event)" class="uk-select">
                      <option value="type">{{$t("organization.quesPurchased.type")}}</option>
                      <option value="QuestionPrice">{{$t("organization.quesPurchased.qPrice")}}</option>
                      <option value="QuestionPoint">{{$t("organization.quesPurchased.qPoint")}}</option>
                      <option value="RegisterDate">{{$t("organization.quesPurchased.rDate")}}</option>
                    </select>
                    <button type="button" class="uk-button uk-button-default"  tabindex="-1" style="height: 20px;">
                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                </div>
                <div class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-right uk-flex-middle">
                  <router-link
                    class="uk-button uk-button-default uk-button-small uk-hidden@m"
                    style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                    title="Question register"
                    tag="a"
                    :to="{name: 'OrganizationQuestion', params:{questionId: 0}}"
                  >
                    <span class="uk-margin-xsmall-right" uk-icon="icon: plus; ratio: .75;"></span>
                    {{$t("organization.quesPurchased.cQues")}}
                  </router-link>
                  <div class="tm-change-view uk-margin-small-left">
                    <ul class="uk-subnav uk-iconnav js-change-view">
                      <li>
                        <router-link
                          class="uk-button uk-button-default uk-button-small"
                          title="Question register"
                          tag="a"
                          style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                          :to="{name: 'OrganizationQuestion', params:{questionId: 0}}"
                        >
                          <span class="uk-margin-xsmall-right" uk-icon="icon: plus; ratio: .95;"></span>
                          {{$t("organization.quesPurchased.cQues")}}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </header>
              <table
                v-if="Questions.length > 0"
                id="example"
                class="uk-table uk-table-hover uk-margin-remove uk-table-striped uk-table-responsive"
                style="width:100%">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        class="checkSize uk-checkbox"
                        v-model="selectAll"
                        @click="select"
                      />
                    </th>
                    <th>{{$t("organization.quesPurchased.eCate")}}</th>
                    <th>{{$t("organization.quesPurchased.id")}}</th>
                     <th>Number</th>
                    <th>{{$t("organization.quesPurchased.type")}}</th>
                    <th>{{$t("organization.quesPurchased.ques")}}</th>
                    <th>{{$t("organization.quesPurchased.mDet")}}</th>
                    <th>{{$t("organization.quesPurchased.qPrice")}}</th>
                    <!-- <th>Question Point</th> -->
                    <th>{{$t("organization.quesPurchased.cloudSet")}}</th>
                    <th>{{$t("organization.quesPurchased.rID")}}</th>
                    <th>{{$t("organization.quesPurchased.rDate")}}</th>
                    <th>{{$t("organization.quesPurchased.edit")}}</th>
                  </tr>
                </thead>
                <tbody >

                    <!-- v-if="item.ownerOrganization != organization.organizId" -->
                  <tr v-for="item in Questions" :item="item" :key="item.id">
                    <td>
                      <input
                        type="checkbox"
                        class="uk-checkbox"
                        v-model="selected"
                        :value="item.id"
                      />
                    </td>
                    <td>{{item.cate1}}  <span class="uk-icon" uk-icon="icon: chevron-right; ratio: .75;"></span>
                     {{item.cate2}}  <span class="uk-icon" uk-icon="icon: chevron-right; ratio: .75;"></span>  {{item.cate3}}</td>

                    <td>
                        {{item.id}}
                    </td>
                    <td>
                      {{item.controlNo}}
                    </td>
                    <!-- <td >
                      <span v-if="item.ownerOrganization != organization.organizId" class="uk-label uk-label-warning">
                      buyed</span>
                      <span v-else class="uk-label">ours</span>
                    </td> -->
                    <td>
                      <p v-if="item.question_type == '1'">Single choice</p>
                      <p v-else-if="item.question_type == '5'">Multiple choice</p>
                      <p v-else-if="item.question_type == '3'">Short answer</p>
                      <p v-else>Speaking</p>
                    </td>
                    <td v-html="item.question.substring(0,100)" class="uk-text-small"></td>
                    <td>
                      <button type="button" v-on:click="questionDetails(item)"
                        class="uk-button uk-button-small uk-button-primary">Details</button>
                    </td>
                    <td>
                      <p v-if="item.free == true">Free: 0</p>
                      <p v-else>Paid: {{item.price}}</p>
                    </td>
                    <!-- <td>{{item.point}}</td> -->
                    <td>
                      <span class="uk-label uk-label-warning uk-padding-remove uk-text-small"
                            v-if="item.exportType == 'onlyme'">Only me</span>
                      <span class="uk-label uk-label-warning uk-padding-remove uk-text-small"
                            v-else-if="item.exportType == 'public'">Public</span>
                      <span class="uk-label uk-label-warning uk-padding-remove uk-text-small"
                            v-else>Private</span>
                    </td>
                    <td>{{item.userName}}</td>
                    <td>{{item.updatedAt.substring(0,10)}}</td>
                    <td>
                      <router-link
                        class="qbtn_d uk-width-1-1 uk-button uk-button-small"
                        style="background-color: #032D67;"
                        tag="a"
                        :to="{name: 'OrganizationQuestion',params: { questionId: item.id}}"
                      >{{ $t('update') }}</router-link>
                      <button
                        class="qbtn_a uk-margin-small-top uk-width-1-1 uk-button uk-button-small"
                        @click="removeTagId(item)"
                        style="background-color: rgb(245, 76, 76);"
                      >{{ $t('delete') }}</button>
                    </td>
                  </tr>
<!--                  <tr  colspan="11" v-else>-->
<!--                  </tr>-->
                </tbody>
              </table>
              <div v-else class="uk-alert-primary" uk-alert>
                <p class="uk-text-center">Empty.</p>
              </div>
            <!-- modal section -->
            <div :id="`modalDetail${organization.organizId}`"  class="uk-modal-container" uk-modal>
              <div v-if="questionDetail!=null"  class="uk-modal-dialog uk-modal-body uk-width-1-1" uk-grid>
                <button class="uk-modal-close-default"  uk-close></button>
                <div class="uk-width-1-3@m uk-padding-remove">
                  <div class="qList uk-padding-bottom uk-margin-remove">
                    <div class="uk-subnav">
                      <h3 class>Question preview</h3>
                    </div>
                    <div class="qu_in">
                      <div id="scriptArea">
                        <div style="border:1px solid #000;margin-bottom:15px" v-if="questionDetail.headerText != ''">
                          <p class="uk-margin-small-left" v-html="questionDetail.headerText"></p>
                        </div>
                      </div>
                      <p class="v_q" v-html="questionDetail.question"></p>
                      <p class="pre_q"></p>
                      <div id="headScript"></div>
                      <div id="mediaList">
                        <template v-for="(item, index) in questionDetail.question_datas">
                          <p v-if="item.media != null" :key="index" width="100%">
                            <img
                              :src="`/uploadingDir/question/${item.media.filename}`"
                              width="100%"
                              alt="item.media.filename"
                            />
                          </p>
                          <p v-else :key="index" class="data_text" v-html="item.data_text"></p>
                        </template>
                      </div>
                      <table class="v_example " width="100%">
                        <tbody id="exampleList">
                          <template v-for="(answer, index) in questionDetail.question_answers">
                            <tr
                              :class="{ 'on': answer.answerFlag == 'true', 'uk-text-bold': answer.answerFlag == 'true'}"
                              :key="index"
                            >
                              <th>{{answer.optionNumber}}</th>
                              <td v-if="answer.media!=null">
                                <img
                                  :src="`/uploadingDir/answer/${answer.media.filename}`"
                                  :alt="answer.media.filename"
                                  class
                                />
                              </td>
                              <td>
                                <p
                                  class="uk-margin-small-left uk-margin-small-top"
                                  v-html="answer.answer"
                                ></p>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="uk-width-2-3@m uk-padding-remove uk-margin-remove-right">
                  <div class="uk-margin-remove">
                    <div class="qList uk-width-1-1 uk-grid uk-margin-remove">
                      <h3 class="uk-margin-left">Question Card</h3>
                      <div class="inform uk-margin-top">
                        <form id="questionInfoForm" onSubmit="return false;">
                          <div >
                            <table class="uk-table uk-table-small uk-table-responsive uk-table-divider"
                              width="100%">
                              <tr>
                                <template>
                                  <td class="tit1">
                                    <span class="input_require">Question type</span>
                                  </td>
                                  <td class="tit2" colspan="6">
                                    <input
                                      type="radio"
                                      value="1"
                                      class="uk-radio"
                                      v-model="questionDetail.question_type"
                                    />
                                    Multiple Choice(A-type)&nbsp;
                                    <input
                                      type="radio"
                                      value="5"
                                      class="uk-radio"
                                      v-model="questionDetail.question_type"
                                    />
                                    Multiple Choice(R-type)&nbsp;
                                    <input
                                      type="radio"
                                      value="3"
                                      class="uk-radio"
                                      v-model="questionDetail.question_type"
                                    />
                                    Short Answer&nbsp;
                                    <input
                                      type="radio"
                                      value="4"
                                      class="uk-radio"
                                      v-model="questionDetail.question_type"
                                    />
                                    Speaking
                                  </td>
                                </template>
                              </tr>
                              <tr>
                                <td class="tit">
                                  <span class="input_require">Category</span>
                                </td>
                                <td colspan="6">
                                  <span class="input_require">Main</span>
                                  <div uk-form-custom="target: > * > span:first-child">
                                    <select
                                      class="uk-select uk-form-width-small uk-form-small"
                                      @change="categoryChange('exam', 'main')"
                                    >
                                      <option value="0">{{questionDetail.cate1}}</option>
                                      <template v-for="(item, index) in mainCategory.main">
                                        <option :value="item.id" :key="index">{{item.name}}</option>
                                      </template>
                                    </select>

                                    <button
                                      class="uk-button uk-button-default uk-button-small"

                                      tabindex="-1"
                                    >

                                      <span uk-icon="icon: chevron-down"></span>
                                    </button>
                                  </div>

                                  <span class="input_require">Middle</span>

                                  <div uk-form-custom="target: > * > span:first-child">
                                    <select
                                      class="uk-select uk-form-width-large uk-form-small"
                                      @change="categoryChange('exam', 'middle')"
                                    >
                                      <option value="0">{{questionDetail.cate2}}</option>
                                      <template v-for="(item, index) in mainCategory.middle">
                                        <option :value="item.id" :key="index">{{item.name}}</option>
                                      </template>
                                    </select>

                                    <button
                                      class="uk-button uk-button-default uk-button-small"

                                      tabindex="-1"
                                    >

                                      <span uk-icon="icon: chevron-down"></span>
                                    </button>
                                  </div>

                                  <span class="input_require">Low</span>

                                  <div uk-form-custom="target: > * > span:first-child">
                                    <select
                                      class="uk-select uk-form-width-large uk-form-small"
                                      @change="categoryChange('exam', 'low')"
                                    >
                                      <option value="0">{{questionDetail.cate3}}</option>
                                      <template v-for="(item, index) in mainCategory.low">
                                        <option :value="item.id" :key="index">{{item.name}}</option>
                                      </template>
                                    </select>

                                    <button
                                      class="uk-button uk-button-default uk-button-small"

                                      tabindex="-1"
                                    >

                                      <span uk-icon="icon: chevron-down"></span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="tit">
                                  <span class="input_require">Number.</span>
                                </td>
                                <td colspan="2">
                                  <input
                                    name="controlNo"
                                    type="text"
                                    class="in1 uk-input uk-form-width-small uk-form-small"
                                    :value="questionDetail.controlNo"
                                  />
                                </td>
                                <td class="subtit">
                                  <span class="input_require">Point</span>
                                </td>
                                <td colspan="3">
                                  <input
                                    name="point"
                                    type="text"
                                    class="in1 uk-input uk-form-width-small uk-form-small"
                                    :value="questionDetail.point"
                                    style="background:#fff"
                                  />
                                  <span>point</span>
                                </td>
                              </tr>
                              <tr>
                                <td class="tit">Subject category</td>
                                <td colspan="6">
                                  Main
                                  <div uk-form-custom="target: > * > span:first-child">
                                    <select
                                      class="uk-select uk-form-width-small uk-form-small"
                                      @change="categoryChange('exam', 'main')"
                                    >
                                      <option value="0">{{questionDetail.scate1}}</option>
                                      <template v-for="(item, index) in subjectCategory.main">
                                        <option :value="item.id" :key="index">{{item.name}}</option>
                                      </template>
                                    </select>

                                    <button
                                      class="uk-button uk-button-default uk-button-small"

                                      tabindex="-1"
                                    >

                                      <span uk-icon="icon: chevron-down"></span>
                                    </button>
                                  </div>Middle
                                  <div uk-form-custom="target: > * > span:first-child">
                                    <select
                                      class="uk-select uk-form-width-small uk-form-small"
                                      @change="categoryChange('exam', 'middle')"
                                    >
                                      <option value="0">{{questionDetail.scate2}}</option>
                                      <template v-for="(item, index) in subjectCategory.middle">
                                        <option :value="item.id" :key="index">{{item.name}}</option>
                                      </template>
                                    </select>

                                    <button
                                      class="uk-button uk-button-default uk-button-small"

                                      tabindex="-1"
                                    >

                                      <span uk-icon="icon: chevron-down"></span>
                                    </button>
                                  </div>Low
                                  <div uk-form-custom="target: > * > span:first-child">
                                    <select
                                      class="uk-select uk-form-width-small uk-form-small"
                                      @change="categoryChange('exam', 'low')"
                                    >
                                      <option value="0">{{questionDetail.scate3}}</option>
                                      <template v-for="(item, index) in subjectCategory.low">
                                        <option :value="item.id" :key="index">{{item.name}}</option>
                                      </template>
                                    </select>

                                    <button
                                      class="uk-button uk-button-default uk-button-small"

                                      tabindex="-1"
                                    >

                                      <span uk-icon="icon: chevron-down"></span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="tit">Question level</td>
                                <td colspan="2">
                                  <input
                                    class="uk-radio"
                                    v-model="question.questionLevel"
                                    name="difficultyLevel"
                                    type="radio"
                                    value="3"
                                  />&nbsp;High&nbsp;
                                  <input
                                    class="uk-radio"
                                    v-model="question.questionLevel"
                                    name="difficultyLevel"
                                    type="radio"
                                    value="2"
                                  />&nbsp;Medium&nbsp;
                                  <input
                                    class="uk-radio"
                                    v-model="question.questionLevel"
                                    name="difficultyLevel"
                                    type="radio"
                                    value="1"
                                  />&nbsp;low
                                </td>
                                <td class="subtit">Estimated correct rate</td>
                                <td colspan="3">
                                  <input
                                    type="text"
                                    :value="questionDetail.correct_rate"
                                    class="in1 uk-input uk-form-width-medium uk-form-small"
                                    style="background:#fff;width:60px;"
                                  />
                                  <span>%</span>
                                  <input
                                    name="correctCk"
                                    type="checkbox"
                                    value
                                    onclick="changeCorreect()"
                                    checked
                                  /> N/A
                                </td>
                              </tr>
                              <tr>
                                <td class="tit uk-margin-left">Knowledge level</td>
                                <td colspan="2">
                                  <label>
                                    <input
                                      class="uk-radio"
                                      v-model="question.knowledgeLevel"
                                      type="radio"
                                      value="1"
                                    /> Memorization&nbsp;
                                  </label>
                                  <label>
                                    <input
                                      class="uk-radio"
                                      v-model="question.knowledgeLevel"
                                      type="radio"
                                      value="2"
                                    /> Analysis
                                  </label>
                                  <br class />
                                  <label>
                                    <input
                                      class="uk-radio"
                                      v-model="question.knowledgeLevel"
                                      type="radio"
                                      value="3"
                                    /> Solution
                                  </label>
                                  <label>
                                    <input
                                      class="uk-radio"
                                      v-model="question.knowledgeLevel"
                                      type="radio"
                                      value="4"
                                    /> N/A
                                  </label>
                                </td>
                                <td class="subtit">Question relevance</td>
                                <td colspan="3">
                                  <input
                                    type="checkbox"
                                    name="qRelevanceCheck"
                                    v-model="question.questionRevevance"
                                    value="1"
                                  /> essential&nbsp;
                                  <br class />
                                  <input
                                    type="checkbox"
                                    name="qRelevanceCheck"
                                    v-model="question.questionRevevance"
                                    value="2"
                                  /> important&nbsp;
                                </td>
                              </tr>
                              <tr>
                                <td class="tit">Topic</td>
                                <td colspan="6">
                                  <input
                                    name="topic"
                                    type="text"
                                    class="uk-input uk-form-width-large uk-form-small"
                                    :value="questionDetail.topic"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td class="tit">Keyword</td>
                                <td colspan="6">
                                  <div>
                                    <input
                                      id="tagss"
                                      name="keyword"
                                      type="text"
                                      :value="questionDetail.keywords"
                                      class="uk-input uk-form-width-large uk-form-small"
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="tit">Reference</td>
                                <td colspan="6">
                                  <div>
                                    <input
                                      name="reference"
                                      type="text"
                                      class="uk-input uk-form-width-large uk-form-small"
                                      :value="questionDetail.reference"
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="tit">Question type</td>
                                <td class colspan="2">
                                  <input
                                    v-model="question.cloudQType"
                                    type="radio"
                                    class="uk-radio"
                                    value="3"
                                  />Public
                                  <input
                                    v-model="question.cloudQType"
                                    type="radio"
                                    class="uk-radio"
                                    value="2"
                                  />Private
                                  <input
                                    v-model="question.cloudQType"
                                    type="radio"
                                    class="uk-radio"
                                    value="1"
                                  />Only me
                                </td>
                                <td class="subtit">Price</td>
                                <td class colspan="3">
                                  <input
                                    type="radio"
                                    class="uk-radio"
                                    value="1"
                                    v-model="question.examType"
                                  />Free
                                  <input
                                    type="radio"
                                    class="uk-radio"
                                    value="2"
                                    v-model="question.examType"
                                  />Paid
                                </td>
                              </tr>
                              <tr>
                                <td class="tit">Permission</td>
                                <td class colspan="2">
                                  <input
                                    v-model="question.permissions"
                                    type="checkbox"
                                    class="uk-checkbox"
                                    value="translate"
                                  />Translate
                                  <input
                                    v-model="question.permissions"
                                    type="checkbox"
                                    class="uk-checkbox"
                                    value="tosell"
                                  />To sell
                                  <input
                                    v-model="question.permissions"
                                    type="checkbox"
                                    class="uk-checkbox"
                                    value="edit"
                                  />Edit
                                </td>
                                <td
                                  v-if="question.examType == '2'"
                                  class="subtit"
                                >Price of the question (Point)</td>
                                <td v-if="question.examType == '2'" colspan="3">
                                  <input
                                    type="text"
                                    class="uk-input uk-form-width-medium uk-form-small subtit"
                                    :value="questionDetail.price"
                                  />
                                  <br class />
                                </td>
                              </tr>
                            </table>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <p class="uk-margin-remove-top uk-flex uk-flex-right uk-visible@m">
                    <a  class="uk-hidden">
                      <!-- @click="prevQuestion(questionDetails.id)" -->
                      <span class="qbtn_b">Prev Question</span>
                    </a>
                    <a>
                      <span class="qbtn_w">Save</span>
                    </a>
                    <a class="uk-hidden">
                      <span class="qbtn_b uk-modal-close">Go to List</span>
                    </a>
                    <a class="uk-hidden">
                      <span class="qbtn_b">Next Question</span>
                    </a>
                  </p>

                  <p class="uk-margin-remove-top uk-flex uk-flex-right uk-width-1-1 uk-button-auto@s uk-hidden@m"
                  uk-grid>
                    <a>
                      <span class="qbtn_b ">Save</span>
                    </a>
                    <a>
                      <span class="qbtn_b uk-modal-close">Go to List</span>
                    </a>
                    <a >
                      <span class="qbtn_b">Prev Question</span>
                    </a>
                    <a>
                      <span class="qbtn_b">Next Question</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="uk-card-footer uk-text-center">
              <div class="uk-flex uk-flex-center">
                <v-pagination
                  v-model="currentPage"
                  :page-count="pages"
                  :classes="uikitClasses"
                  :labels="Labels"
                  @change="onChange"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </section>

      <page-footer/>
    </div>
  </layout>
</template>


<script scoped>
import Layout from "../../layouts/Layout"
import UserService from "@/services/UserService"
import vPagination from "@/components/globals/vue-plain-pagination"
import { mapGetters } from "vuex"
import QuestionService from "@/services/QuestionService"
import CategoryDataService from "@/services/CategoryDataService"
import UIkit from 'uikit'
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
export default {
  name: "Home",
  components: {
    Layout,
    vPagination,
    PageHeader,
    PageFooter
  },
  data() {
    return {
      organization: {},
      questionPrice: "",
      qInputPrice: 0,
      purchase: "true",
      checked: "",
      questionDetail: [],
      newCount: 0,
      sortselected: "type",
      Questions: [],
      currentPage: 1,
      pages: 0,
      mainCategory: {
        main: [],
        middle: [],
        low: []
      },
      subjectCategory: {
        main: [],
        middle: [],
        low: []
      },
      question: {
        permissions:[],
        questionType: "",
        correctRate: "",
        mainCategory: {
          main: "0",
          middle: "0",
          low: "0"
        },
        bulletedListType: [
          {
            first: "",
            second: "",
            third: "",
            fourth: "",
            fifth: ""
          }
        ],
        point: 0,
        subjectCategory: {
          main: "0",
          middle: "0",
          low: "0"
        },
        questionLevel: "",
        estimatedCorrectRate: "",
        knowledgeLevel: "",
        questionRevevance: [],
        topic: "",
        keywords: [],
        reference: "",
        headerText: "",
        questionData: "",
        presentDatas: [
          {
            fileName: "",
            type: "text",
            file: "",
            text: ""
          }
        ],
        answers: [
          {
            fileName: "",
            type: "text",
            file: "",
            text: "",
            correctAnswer: false,
            optionNumber: ""
          }
        ],
        wrongAnswer: [],
        examType: "",
        examPrice: "",
        cloudQType: "",
        permission: []
      },
      search: {
        cate1: "",
        cate2: "",
        cate3: "",
        scate1: "",
        scate2: "",
        scate3: "",
        question: "",
        keyword: "",
        point: "",
        qtype: "",
        qlevel: ""
      },
      uikitClasses: {
        ul: "uk-pagination",
        li: "",
        liActive: "uk-active",
        liDisable: "uk-disabled",
        button: "page-link"
      },
      Labels: {
        first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
        prev: "<span uk-pagination-previous></span>",
        next: "<span uk-pagination-next></span>",
        last: "Last"
      },
      selected: [],
      selectAll: false
    };
  },
  filters: {
  },
  created() {
    this.organization = this.currentUser.organizs[0]
    console.log("this is current user")
    console.log(this.currentUser)
  },
  async mounted() {
    this.loadCates("main", 1, 0);
    this.loadCates("subject", 1, 0);
  },
  methods: {
    // async prevQuestion(item){
    //   this.questionDetail = null
    //   this.questionDetails([--item])
    // },
    async questionDetails(item) {
      try {
        this.questionDetail = null
        const response = await QuestionService.getQuestionDetails(item.id);
        if (response.data.status == 200) {
          this.questionDetail = response.data.result.questionDetail
          this.questionDetail.cate1 = item.cate1
          this.questionDetail.cate2 = item.cate2
          this.questionDetail.cate3 = item.cate3
          this.questionDetail.scate1 = item.scate1
          this.questionDetail.scate2 = item.scate2
          this.questionDetail.scate3 = item.scate3
          this.questionDetail.correct_rate = item.correct_rate
          this.questionDetail.headerText = item.headerText
          this.questionDetail.questiontype = item.question_type
          this.questionDetail.topic = item.topic
          this.questionDetail.reference = item.reference
          this.questionDetail.permissions = item.permissions
          if (this.questionDetail.difficulty_level == "1") {
            this.question.questionLevel = 1;
          }
          if (this.questionDetail.difficulty_level == "2") {
            this.question.questionLevel = 2;
          }
          if (this.questionDetail.difficulty_level == "3") {
            this.question.questionLevel = 3;
          }
          if (this.questionDetail.knowledgeLevel == "1") {
            this.question.knowledgeLevel = 1;
          }
          if (this.questionDetail.knowledgeLevel == "2") {
            this.question.knowledgeLevel = 2;
          }
          if (this.questionDetail.knowledgeLevel == "3") {
            this.question.knowledgeLevel = 3;
          }
          if (this.questionDetail.knowledgeLevel == "4") {
            this.question.knowledgeLevel = 4;
          }
          if (this.questionDetail.free == true) {
            this.question.examType = 1;
          }
          if (this.questionDetail.free == false) {
            this.question.examType = 2;
          }
          if (this.questionDetail.exportType == "public") {
            this.question.cloudQType = 3;
          }
          if (this.questionDetail.exportType == "private") {
            this.question.cloudQType = 2;
          }
          if (this.questionDetail.exportType == "onlyme") {
            this.question.cloudQType = 1;
          }

          if (this.questionDetail.qRelevance.length > 0)
            {
              if (this.questionDetail.qRelevance.includes(","))
                this.question.questionRevevance = this.questionDetail.qRelevance.split(',')

              else
                this.question.questionRevevance[0] = this.questionDetail.qRelevance
            }
            if (this.questionDetail.permissions.length > 0) {
              if (this.questionDetail.permissions.includes(","))
                this.question.permissions = this.questionDetail.permissions.split(',')
              else
                this.question.permissions[0] = this.questionDetail.permissions
            }
          console.log("this question Details")
          console.log(response.data)
          console.log(this.currentUser.organizs[0].organizId)
          UIkit.modal("#modalDetail"+this.currentUser.organizs[0].organizId).show();
        } else {
          alert("ERROR: " + response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    defaultSetting(){
      window.location.reload(true);
    },
    // delete question service хийх
    async removeTagId(item) {
      try {
        this.selected = [item.id];
        const response = await QuestionService.deleteQuestion(this.selected);
        if (response.data.status == 200) {
          alert("deleted it");
          this.currentPage = 0;
          this.loaddata(this.currentPage);
        } else {
          alert(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    answerTypeChange(type) {
      this.answerType = type;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let item in this.Questions) {
          this.selected.push(this.Questions[item].id);
        }
      }
    },
    async removeSelected() {
      try {
        if (this.selected.length != 0) {
          await QuestionService.deleteQuestion(this.selected);
          alert("Deleted items:" + "ID = " + this.selected);
          this.currentPage = 0;
          this.loaddata(this.currentPage);
        } else {
          alert("you need to choose your questions before !!! ");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchFilter() {
      this.loaddata(this.currentPage);
    },
    categoryChange(type, catelevel) {
      if (type == "exam") {
        type = "main";
      } else {
        type = "subject";
      }
      if (catelevel == "main") {
        let parentId = 0;
        if (type == "main") parentId = this.search.cate1;
        else parentId = this.search.scate1;
        this.loadCates(type, 2, parentId);
      }
      if (catelevel == "middle") {
        let parentId = 0;
        if (type == "main") parentId = this.search.cate2;
        else parentId = this.search.scate2;
        this.loadCates(type, 3, parentId);
      }
    },
    async loadCates(type, cateStep, parentId) {
      try {
        const response = await CategoryDataService.getCategoriesStep({
          type: type,
          cateStep: cateStep,
          parentId: parentId
        });
        if (response.data.status === 200) {
          if (type == "main") {
            //Category
            if (cateStep == 1) {
              this.mainCategory.main = response.data.result.cates;
            }
            if (cateStep == 2) {
              this.mainCategory.middle = response.data.result.cates;
            }
            if (cateStep == 3) {
              this.mainCategory.low = response.data.result.cates;
            }
          } else {
            //subject category
            if (cateStep == 1) {
              this.subjectCategory.main = response.data.result.cates;
            }
            if (cateStep == 2) {
              this.subjectCategory.middle = response.data.result.cates;
            }
            if (cateStep == 3) {
              this.subjectCategory.low = response.data.result.cates;
            }
          }
        } else {
          alert("ERROR: " + response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    onChangeSort() {
      this.loaddata(this.currentPage);
    },
    searchDate() {
      this.loaddata(this.currentPage);
    },
    async loaddata(page) {
      try {
        if (page > 0) {
          page = page - 1;
        }
        const response = await QuestionService.getQuestions({
          page: page,
          sort: this.sortselected,
          organizId: this.currentUser.organizs[0].organizId,
          question: this.search.question,
          point: this.search.point,
          keyword: this.search.keyword,
          qtype: this.search.qtype,
          qlevel: this.search.qlevel,
          cate1: this.search.cate1,
          cate2: this.search.cate2,
          cate3: this.search.cate3,
          scate1: this.search.scate1,
          scate2: this.search.scate2,
          scate3: this.search.scate3
        });
        if (response.status == 200) {
          for (let i = 0; i < response.data.content.length; i++)
          {
            if (response.data.content[i].ownerOrganization != this.organization.organizId)
            {
              this.Questions.push(response.data.content[i])
              this.currentPage = response.data.page;
              this.pages = response.data.totalPages;
            }
          }
          // if (!this.Questions.length) {
          //   this.isLoading = false;
          //   return;
          // }
        }
      } catch (error) {
        console.log(error.response);
        this.error = error.response.data.message;
        this.scrollToTop();
      }
    },
    async newRequestsCount() {
      try {
        const response = await UserService.getNewRequestCount({
          organizId: this.currentUser.organizs[0].organizId
        });
        //   console.log(response)
        if (response.status == 200) {
          this.newCount = response.data.result.newRequest;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onChange: function() {
      // eslint-disable-next-line no-console
      console.log(`"currentPage" has been changed`);
      this.loaddata(this.currentPage);
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "currentUser"])
  }
};
</script>

<style scoped>
.uk-pagination > li > a {
  font-size: 18px !important;
}
.inform {
  width: 100%;
  margin: 0 auto;
  float: right;
}
.inform .answer {
  font-weight: bold;
  padding-left: 5px;
  padding-bottom: 5px;
  text-indent: 58px;
  font-size: 14px;
}
.answer {
  padding: 8px 0 0 27px;
  color: #000;
}
.example_box .check {
  float: left;
  height: 38px;
  width: 40px;
  border: 1px solid #999;
  margin-right: 15px;
  cursor: pointer;
}
.example_box li .check.on {
  border: 1px solid #0091de;
  background: url("../../assets/q_check.png") no-repeat;
  z-index: 99999999999;
  position: relative;
}
#exampleList .example_box .example > a {
  position: absolute;
  right: 0;
  z-index: 999;
  top: 0;
  width: 41px;
}
#exampleList .example_box .example .ck {
  margin-left: 113px;
  margin-top: -40px;
  width: 675px;
}
.example_box .check {
  float: left;
  height: 38px;
  width: 40px;
  border: 1px solid #999;
  margin-right: 15px;
  cursor: pointer;
}
.example .numb_list_sub {
  float: left;
  height: 22px;
  width: 40px;
  border: 1px solid #999;
  margin-right: 15px;
  text-align: center;
  padding: 8px 0;
  font-size: 20px;
}
.uk-table  td {
  font-size: 13px;
}
.uk-table tr {
  border-top: 1px solid #dee2fe;
  border-bottom: 1px solid #dee2fe;
}
.uk-table td.tit {
  border-right: 1px solid #dee2fe;
  border-top: 1px solid #dee2fe;
  font-size: 13px;
  text-align: left;
  /*font-weight: bold;*/
  /*color: #404348;*/
  /*background-color: #eeeeef;*/
}
.uk-table td.tits {
  border: 1px solid #dee2fe;
  font-size: 13px;
  /*font-weight: bold;*/
  /*color: #404348;*/
  /*background-color: #eeeeef;*/
}

.inform .table table td.tit2 {
  border: 1px solid #dee2fe;
  border-right: 0px;
  border-left: 0px;
  height: 30px;
  padding-left: 15px;
  color: #999;
}
.inform .table table td {
  border-bottom: 1px solid #dee2fe;
  padding-left: 15px;
  color: #999;
}
.inform .table table td input.in1 {
  width: auto;
  height: 26px;
  background: #e3e2e7;
  border: 1px solid #b1b1ae;
  padding-left: 5px;
}
.inform .table td input.in3 {
  background: #e3e2e7;
  border: 1px solid #b1b1ae;
  padding-left: 5px;
}
.inform .table table td.subtit,
.preform .table table td.subtit {
  border: 1px solid #dee2fe;
  border-top: 1px solid #dee2fe;
  font-size: 13px;
  font-weight: bold;
  color: #404348;
  background-color: #eeeeef;
}
.cloud h4 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.3em;
  font-weight: 600;
  color: rgb(1, 176, 255);
  padding-left: 15px;
  border-left: 2px solid rgb(1, 176, 255);
}
.qList h3 {
  /* height: 30px; */
  /* margin-right: 20px; */
  padding-top: 10px;
  font-size: 1.3em;
  font-weight: 600;
  color: rgb(1, 176, 255);
  padding-left: 15px;
  border-left: 2px solid rgb(1, 176, 255);
}
#headerTextDiv {
  position: relative;
}
#headerTextDiv .text_close {
  position: absolute;
  width: 35px;
  height: 20px;
  z-index: 1;
  right: 0;
  top: 0;
}
.G_line {
  border-bottom: 1px dashed #999;
  padding-top: 30px;
  width: 990px;
  float: right;
  text-align: center;
}
ul {
  padding: 0;
}
ul > li {
  list-style: none;
  display: inline-block;
  padding: 0;
}
.qList .btn ul {
  display: inline-block;
  border-bottom: 1px solid #9dadc4;
  /* width: 100%; */
}
.qList h3.input_require {
  color: #e90000;
}
.qList .btn ul {
  border-bottom: 1px solid #9dadc4;
}
.mediaList .media.image {
  background-image: url("../../assets/img_over_en.gif") !important;
}
.mediaList .media.image {
  background-repeat: no-repeat;
  background-position: top left;
}
.upload_box {
  margin: 0 10px 10px 54px;
  width: 725px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  position: relative;
}
.upload_box ul {
  height: 26px;
}
.upload_box .route input {
  border: 1px solid #ccc;
  background-color: #fcfcfa;
  height: 20px;
  line-height: 18px;
  width: 400px;
}
.upload_box .close {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
}
.upload_box input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  width: 507px;
}
.upload_box .route .loadbtn {
  float: right;
  padding: 0 20px;
  background-color: #4c6172;
  color: #fff;
  text-align: center;
  margin-top: 1px;
}
.inform .volume {
  text-align: left;
  padding-left: 2px;
  color: #888 !important;
  font-size: 0.9em;
}
.inform .volume > em {
  color: #888 !important;
}
.mediaList .media.audio {
  background-image: url("../../assets/audio_over_en.gif") !important;
  background-repeat: no-repeat;
  background-position: top left;
}
.mediaList .media.video {
  background-image: url("../../assets/video_over_en.gif");
  background-repeat: no-repeat;
  background-position: top left;
}
#mediaList .text_box {
  background-image: url("../../assets/text_over_en.gif");
  background-repeat: no-repeat;
  background-position: top left;
}
.text_box {
  position: relative;
  left: 0;
  top: 0;
  width: 860px;
  margin-bottom: 15px;
  padding-left: 53px;
}
#mediaList .text_box .text_close {
  position: absolute;
  right: 0;
  top: 0;
  width: 43px;
  z-index: 1;
  text-align: center;
}
.upload_box .explain input {
  border-bottom: 1px solid #ccc;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #f5f5f5;
  width: 430px;
}
#mediaList .upload_box .data_img {
  position: relative;
  top: -20px;
  left: 40px;
  width: 100px;
  height: 70px;
}
.qList .option .order {
  border: 1px solid #ccc;
  background-color: #fafafa;
  margin-bottom: 14px;
  padding: 15px;
  font-weight: bold;
  color: #000;
  font-size: 1.2em;
}
.answer {
  background: url("../../assets/ar_m.png") 9px 6px no-repeat;
  padding: 8px 0 0 27px;
  color: #000;
}
.exampleList ul li {
  display: block;
}
.uk-label-warning{
  padding: 6px;
  width: 60px;
}
.add {
  text-align: right;
  font-size: 1em;
  padding-right: 5px;
  padding-top: 5px;
  text-decoration: underline;
  font-weight: bold;
}
.border {
  border: 1px solid black;
}
.uk-table th {
  padding: 16px 7px;
}
.data_text {
  border: 1px solid #000;
  margin-bottom: 15px;
}
/* checkSize */
.checkSize {
  display: inline-block;
  height: 25px;
  width: 25px;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 1px solid #cccccc;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
}
/* answer style */
.qu_in .v_example tr.on th {
  background: url("../../assets/v_on.png") center 7px no-repeat;
  color: #fff;
}
.qu_in .v_example tr th {
  width: 12%;
  padding: 11px 0;
  background: url("../../assets/v_off.png") center 7px no-repeat;
  vertical-align: text-top;
}
/* button */
.qbtn_a {
  border: 1px solid #fff;
  display: inline-block;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
}
.qbtn_d {
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
}
.qbtn_b {
  display: inline-block;
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #032d67;
  color: #fff;
  font-weight: 700;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  font-size: 1.1em;
  min-width: 50px;
  text-align: center;
}
.qbtn_w {
  display: inline-block;
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #fff;
  color: #000;
  border: 1px solid #9ea5a7;
  font-weight: 700;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  font-size: 1.1em;
  min-width: 50px;
  text-align: center;
}
</style>
